
import {
  defineComponent,
  onMounted,
  onUnmounted,
  computed,
  ref
} from "vue";
import HistoryService from "../services/historyService";
import getIndicator from "../utility/getIndicator";
import convertDate from "../utility/convertDate";
import CustomIcon from "../components/CustomIcon.vue";

import SkeletonLoader from "../components/SkeletonLoader.vue";
import HistoryModule from "../store/modules/History/history";
import ContentModule from "../store/modules/Content/content";
import UserModule from "../store/modules/User/user";
import UIConfigurationModule from "../store/modules/UIConfiguration/uiConfiguration";
import TableSettingsModule from "../store/modules/TableSettings/tableSettings";
import checkIfEligible from "../utility/AvsRecallEligibilityCheck";
import PreviewModalFunctionality from "@/composables/PreviewModalFunctionality";

const HistoryRow = () => import("../components/HistoryRow.vue");
const AvsRecallModal = () => import("../components/AVSRecallModal.vue");
const PreviewModal = () => import("../components/PreviewModal.vue");
export default defineComponent({
  components: { CustomIcon, HistoryRow, SkeletonLoader, AvsRecallModal, PreviewModal },

  setup () {
    const isGoToTopVisible = ref(false);
    const AvsRecallInfo = UIConfigurationModule.getAvsRecallInfo;
    const {
      setPreviewInfo,
      itemsPerPage,
      pageNumber,
      isPreviewLoading,
      isPreviewVisible,
      previewMaterial,
      closePreview,
      showNextContentItem,
      showPreviousContentItem,
    } = PreviewModalFunctionality({});
    const onScroll = (e) => {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      isGoToTopVisible.value = top > 20;
    };

    const getHistory = () => {
      return HistoryService.getActivityHistory().then((res) => {
        if (AvsRecallInfo.AvsRecallEnabled) {
          for (let i = 0; i < res.length; i++) {
            res[i].AvsEligible = checkIfEligible(res[i], AvsRecallInfo);
          }
        }
        HistoryModule.setHistory(res);
      });
    };
    const content = computed(() => HistoryModule.getHistory);

    const selectedMaterials = computed(() => {
      return ContentModule.getselectedMaterials;
    });

    const displaySettings = computed(() => {
      return UserModule.displaySettings.activityHistory;
    });

    const loading = ref(true);
    const recallModalOpen = ref(false);
    const initializeContent = async () => {
      await getHistory();
      loading.value = false;
    };

    onMounted(() => {
      initializeContent();
      TableSettingsModule.updateHistoryView(true);
      TableSettingsModule.updatePageNumber(1);
    });

    onUnmounted(() => {
      TableSettingsModule.updateHistoryView(false);
    });

    const openRecallModal = () => {
      recallModalOpen.value = true;
    };
    return {
      getIndicator,
      convertDate,
      content,
      isGoToTopVisible,
      onScroll,
      selectedMaterials,
      loading,
      displaySettings,
      pageNumber,
      itemsPerPage,
      recallModalOpen,
      openRecallModal,
      AvsRecallInfo,
      isPreviewLoading,
      isPreviewVisible,
      previewMaterial,
      setPreviewInfo,
      closePreview,
      showPreviousContentItem,
      showNextContentItem,
    };
  },
});
