
function convertDateToUTC (date: Date) {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
}

function checkForDays (storedDate: Date, today: Date, amount: number): boolean {
  const dayInMillis = 86400000;
  const days = Math.floor((convertDateToUTC(today) - convertDateToUTC(storedDate)) / dayInMillis);
  return days <= amount;
}

function checkForHours (storedDate: Date, today: Date, amount: number): boolean {
  const hourInMillis = 3600000;
  const hours = Math.floor((convertDateToUTC(today) - convertDateToUTC(storedDate)) / hourInMillis);
  return hours <= amount;
}

function checkForMinutes (storedDate: Date, today: Date, amount: number): boolean {
  const minuteInMillis = 60000;
  const minutes = Math.floor((convertDateToUTC(today) - convertDateToUTC(storedDate)) / minuteInMillis);
  return minutes <= amount;
}

export default function checkIfEligible (material, avsRecallSettings): boolean {
  const storedDate = new Date(material.Date);
  const today = new Date();
  const amount = parseInt(avsRecallSettings.AvsRecallTime.slice(0, -1));
  const type = avsRecallSettings.AvsRecallTime.slice(-1);
  switch (type) {
    case "d":
      return checkForDays(storedDate, today, amount);
    case "h":
      return checkForHours(storedDate, today, amount);
    case "m":
      return checkForMinutes(storedDate, today, amount);
    default:
      return false;
  }
}
